var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.$emit('on-Submit', _vm.promotionForm)}}},[_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Title","label-for":"mc-title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('b-form-input',{attrs:{"placeholder":"Title"},model:{value:(_vm.promotionForm.title),callback:function ($$v) {_vm.$set(_vm.promotionForm, "title", $$v)},expression:"promotionForm.title"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Content","label-for":"mc-content"}},[_c('validation-provider',{attrs:{"name":"Content","rules":"max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_c('quill-editor',{attrs:{"options":_vm.snowOption},model:{value:(_vm.promotionForm.content),callback:function ($$v) {_vm.$set(_vm.promotionForm, "content", $$v)},expression:"promotionForm.content"}})],_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"col-4",attrs:{"label-for":"mc-is-button"}},[_c('validation-provider',{attrs:{"name":"Show Button"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"name":"is-button","switch":"","inline":""},model:{value:(_vm.promotionForm.is_button),callback:function ($$v) {_vm.$set(_vm.promotionForm, "is_button", $$v)},expression:"promotionForm.is_button"}},[_vm._v(" Show Button ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.promotionForm.is_button)?_c('b-form-group',{staticClass:"col-12",attrs:{"label":"Link","label-for":"mc-link"}},[_c('validation-provider',{attrs:{"name":"Link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('b-form-input',{attrs:{"placeholder":"Link"},model:{value:(_vm.promotionForm.link),callback:function ($$v) {_vm.$set(_vm.promotionForm, "link", $$v)},expression:"promotionForm.link"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3465078076)}),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body mt-1"},[_c('span',[_vm._v("Valid URL should contain http or https.")])])])],1)],1)],1):_vm._e()],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"image"}},[_vm._v("Background Image")]),_c('div',{staticClass:"helper"}),_c('div',{staticClass:"drop display-inline align-center",on:{"dragover":function($event){$event.preventDefault();},"drop":_vm.onDrop}},[_c('div',{staticClass:"helper"}),(!_vm.imagePreview && !_vm.promotionForm.image)?_c('label',{staticClass:"btn btn-primary display-inline"},[_vm._v(" SELECT OR DROP AN IMAGE "),_c('input',{attrs:{"type":"file","accept":"image/*","name":"image"},on:{"change":_vm.onChange}})]):_vm._e(),(_vm.imagePreview)?_c('div',{staticClass:"display-inline align-center",class:{ image: true }},[_c('img',{staticClass:"img my-2",attrs:{"src":_vm.imagePreview,"alt":"Image"}}),_c('button',{staticClass:"btn btn-primary mb-2",on:{"click":_vm.removeFile}},[_vm._v(" REMOVE ")])]):_vm._e(),(_vm.promotionForm.image && !_vm.imagePreview)?_c('div',{staticClass:"display-inline align-center",class:{ image: true }},[_c('img',{staticClass:"img mt-2",attrs:{"src":_vm.promotionForm.image,"alt":"Image"}}),_c('br'),_c('br'),_c('button',{staticClass:"btn btn-primary mb-2",on:{"click":_vm.removeFile}},[_vm._v(" REMOVE ")])]):_vm._e()])]),_c('b-col',{attrs:{"md":"6"}},[[_c('div',{staticClass:"box"},[_c('section',[_c('validation-provider',{attrs:{"name":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"ml-1 mt-1",attrs:{"label":"Select Start-End Date","label-for":"mc-select-date","state":errors.length > 0 ? false : null}},[_c('date-picker',{attrs:{"type":"date","range":"","placeholder":"Select date range"},model:{value:(_vm.promotionForm.startEndDate),callback:function ($$v) {_vm.$set(_vm.promotionForm, "startEndDate", $$v)},expression:"promotionForm.startEndDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)])]],2)],1),_c('b-col',{staticClass:"demo-inline-spacing",attrs:{"md":"12"}},[_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"primary","type":"submit"}},[_vm._v(" Submit "),(_vm.loading)?_c('div',{staticClass:"spinner-border spinner-border-sm"}):_vm._e()]),_c('b-button',{attrs:{"variant":"secondary","to":{
            name:
              _vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin || _vm.checkAccess.view
                ? 'promotion-banner-list'
                : 'dashboard',
          }}},[_vm._v(" Cancel ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }