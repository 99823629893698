import axios from "axios";

function getPromotionListing(data){
    return axios.get('promotion/list', {
        params: data
    })
}

function addPromotion(data) {
    return axios.post('promotion', data);
}

function editPromotion(id, data) {
    return axios.put(`promotion/${id}`, data);
}

function getPromotionById(id) {
    return axios.get(`promotion/${id}`);
}

function deletePromotion(id) {
    return axios.delete(`promotion/${id}`);
}

function activeInactivePromotion(id) {
    return axios.put(`promotion/active-deactive/${id}`);
}

export default {
    getPromotionListing,
    addPromotion,
    editPromotion,
    getPromotionById,
    deletePromotion,
    activeInactivePromotion
}