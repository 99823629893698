<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="$emit('on-Submit', promotionForm)">
        <b-row ref="row">
          <b-col md="12">
            <b-form-group label="Title" label-for="mc-title">
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="max:50"
              >
                <div>
                  <b-form-input
                    v-model="promotionForm.title"
                    placeholder="Title"
                  />
                </div>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Content" label-for="mc-content">
              <validation-provider
                #default="{ errors }"
                name="Content"
                rules="max:250"
              >
                <template>
                  <quill-editor
                    v-model="promotionForm.content"
                    :options="snowOption"
                  />
                </template>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group class="col-4" label-for="mc-is-button">
              <validation-provider #default="{ errors }" name="Show Button">
                <b-form-checkbox
                  v-model="promotionForm.is_button"
                  name="is-button"
                  switch
                  inline
                  class="mt-2"
                >
                  Show Button
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              class="col-12"
              v-if="promotionForm.is_button"
              label="Link"
              label-for="mc-link"
            >
              <validation-provider
                #default="{ errors }"
                name="Link"
                rules="required"
              >
                <div>
                  <b-form-input
                    v-model="promotionForm.link"
                    placeholder="Link"
                  />
                </div>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-row>
                <b-col md="12">
                  <b-alert variant="primary" show>
                    <div class="alert-body mt-1">
                      <span>Valid URL should contain http or https.</span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col md="6" class="mt-2">
            <label for="image">Background Image</label>
            <div class="helper" />
            <div
              class="drop display-inline align-center"
              @dragover.prevent
              @drop="onDrop"
            >
              <div class="helper" />
              <label
                v-if="!imagePreview && !promotionForm.image"
                class="btn btn-primary display-inline"
              >
                SELECT OR DROP AN IMAGE
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  @change="onChange"
                >
              </label>
              <div
                v-if="imagePreview"
                class="display-inline align-center"
                :class="{ image: true }"
              >
                <img :src="imagePreview" alt="Image" class="img my-2">
                <button class="btn btn-primary mb-2" @click="removeFile">
                  REMOVE
                </button>
              </div>
              <div
                v-if="promotionForm.image && !imagePreview"
                class="display-inline align-center"
                :class="{ image: true }"
              >
                <img
                  :src="promotionForm.image"
                  alt="Image"
                  class="img mt-2"
                >
                <br>
                <br>
                <button class="btn btn-primary mb-2" @click="removeFile">
                  REMOVE
                </button>
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <template>
              <div class="box">
                <section>
                  <validation-provider
                    #default="{ errors }"
                    name="Date"
                  >
                    <b-form-group
                      class="ml-1 mt-1"
                      label="Select Start-End Date"
                      label-for="mc-select-date"
                      :state="errors.length > 0 ? false : null"
                    >
                      <date-picker
                        v-model="promotionForm.startEndDate"
                        type="date"
                        range
                        placeholder="Select date range"
                      />
                      <!-- @change="change" -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </section>
              </div>
            </template>
          </b-col>
        </b-row>

        <!-- submit button -->
        <b-col md="12" class="demo-inline-spacing">
          <b-button :disabled="loading" variant="primary" type="submit">
            Submit
            <div v-if="loading" class="spinner-border spinner-border-sm" />
          </b-button>
          <b-button
            variant="secondary"
            :to="{
              name:
                checkLoginRole() === RoleEnum.SuperAdmin || checkAccess.view
                  ? 'promotion-banner-list'
                  : 'dashboard',
            }"
          >
            Cancel
          </b-button>
        </b-col>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormCheckbox,
  BAlert,
} from "bootstrap-vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { required } from "@core/utils/validations/validations";
import { RoleEnum } from "@/helpers/constant";
import checkLoginRole from "@/helpers/checkLoginRole";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    quillEditor,
    BFormInput,
    BFormCheckbox,
    BAlert,
    DatePicker,
  },
  props: {
    promotionData: {
      type: Object,
      required: true,
    },
    isLoaded: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      promotionForm: {
        id: "",
        image: "",
        title: "",
        content: "",
        is_button: true,
        link: "",
        status: "",
        startEndDate: this.promotionData?.dateArray || [],
      },
      imagePreview: "",
      required,
      RoleEnum,
      checkLoginRole,
      loading: false,
      checkAccess: {},
      toolbarOptions: [],
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "align", "link"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      },
    };
  },

  mounted() {
    this.promotionForm.image = this.promotionData.image;
    this.promotionForm.title = this.promotionData.title;
    this.promotionForm.content = this.promotionData.content;
    this.promotionForm.is_button = this.promotionData.is_button;
    this.promotionForm.link = this.promotionData.link;
    this.promotionForm.startEndDate = this.promotionData.dateArray;
    this.promotionForm.status = this.promotionData.status;
    this.promotionForm.id =
      this.$route.name === "promotion-banner-add"
        ? ""
        : this.$route.name === "promotion-banner-edit"
        ? this.promotionData.id
        : "";
  },

  methods: {
    async validationForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          valid = true;
        } else {
          valid = false;
        }
      });
      return valid;
    },
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const { files } = e.dataTransfer;
      this.createFile(files[0]);
    },
    onChange(e) {
      const { files } = e.target;
      this.createFile(files[0]);
    },
    createFile(file) {
      if (
        (file.type === "image/jpeg"
          || file.type === "image/jpg"
          || file.type === "image/png"
          || file.type === "image/gif"
          || file.type === "video/mp4")
        && file.size <= 1048576
      ) {
        this.promotionForm.image = file;
        this.imagePreview = URL.createObjectURL(file, file.name);
      } else {
        this.promotionForm.image = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Only JPG, JPEG, GIF or PNG Allowed with Max Size Of 1 MB",
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },
    removeFile() {
      this.promotionForm.image = "";
      this.imagePreview = "";
    },
    disabledRange(date) {
      return date < new Date();
    },
  },
};
</script>

<style>
.ql-editor {
  min-height: 150px;
  max-height: 350px;
}


.dropBtn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 15px 35px;
  position: relative;
}

.dropBtn:hover {
  background-color: #722040;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

/* .helper {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  width: 0;
} */

.hidden {
  display: none !important;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.img,
#video {
  border: 1px solid #f6f6f6;
  display: inline-block;
  width: 400px;
  height: 250px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 0 10px;
}

.video {
  border: 1px solid #f6f6f6;
  display: inline-block;
  height: auto;
  width: auto;
  max-height: 250px;
  max-width: 400px;
}

.drop {
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  background-color: #f6f6f6;
  border-radius: 2px;
  height: 400px;
  width: 600px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
