<template>
  <div id="app">
    <!-- <h3></h3> -->
    <PromotionAdd
      v-if="!id && !isLoaded"
      ref="simpleRules"
      :key="$route.fullPath"
      :is-loaded="isLoaded"
      :promotion-data="PromotionDetails"
      @on-Submit="createPromotion"
    />

    <!-- <h3>Edit promotion form</h3> -->
    <PromotionAdd
      v-if="id && isLoaded"
      ref="simpleRules"
      :key="$route.fullPath"
      :is-loaded="isLoaded"
      :promotion-data="PromotionDetails"
      @on-Submit="updatePromotion"
    />
  </div>
</template>

<script>
import promotionService from "@/services/promotion/promotion.service";
import { resConditionCheck } from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PromotionAdd from "./PromotionAdd.vue";
import moment from 'moment';

export default {
  name: "FaqAddEdit",
  components: { PromotionAdd },
  data() {
    return {
      isLoaded: false,
      PromotionDetails: {},
      id: this.$route.params.id ? this.$route.params.id : "",
    };
  },

  async mounted() {
    if (this.$route.params.id) {
      await this.getPromotionById(this.$route.params.id);
    }
  },
  methods: {
    async createPromotion(promotionForm) {
      console.log("padasd",promotionForm)
      const valid = await this.$refs.simpleRules.validationForm();
      if (!promotionForm.image) {
        this.$refs.simpleRules.loading = true
        setTimeout(() => {
          this.$refs.simpleRules.loading = false
        }, 3000)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Promotion Background Image is required',
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
      if (valid && (promotionForm.image && promotionForm.image !== undefined && promotionForm.image !== null && promotionForm.image !== '')) {
        this.$refs.simpleRules.loading = true;
        const formdata = new FormData();
        let start_date = promotionForm.startEndDate !== undefined ? moment(promotionForm?.startEndDate?.[0]).format('YYYY-MM-DD') : '';
        let end_date = promotionForm.startEndDate !== undefined ? moment(promotionForm?.startEndDate?.[1]).format('YYYY-MM-DD') : '';
        formdata.append("image", promotionForm && promotionForm.image ? promotionForm.image : '')
        formdata.append("title", promotionForm && promotionForm.title ? promotionForm.title : '')
        formdata.append("content", promotionForm && promotionForm.content ? promotionForm.content : '')
        formdata.append("is_button", promotionForm && promotionForm.is_button ? promotionForm.is_button : false)
        formdata.append("link", promotionForm && promotionForm.link ? promotionForm.link : '')
        formdata.append("start_date", start_date !== undefined ? start_date : '')
        formdata.append("end_date", end_date  !== undefined ? end_date : '')
        formdata.append("status", promotionForm && promotionForm.status ? promotionForm.status : 0)
        await promotionService.addPromotion(formdata)
          .then((res) => {
            this.$refs.simpleRules.loading = false;
            if (resConditionCheck(res.status) && res.data.data) {
              this.$router.push("/content/promotion-banner/list");
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          })
          .catch((error) => {
            this.$refs.simpleRules.loading = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    async getPromotionById(id) {
      await promotionService
        .getPromotionById(id)
        .then((res) => {
          this.isLoaded = true;
          if (resConditionCheck(res.status) && res.data.data) {
            this.PromotionDetails = {
              id: res.data.data.id,
              image: res.data.data.image,
              title: res.data.data.title,
              content: res.data.data.content,
              is_button: res.data.data.is_button,
              link: res.data.data.url,
              status: res.data.data.status,
              start_date: res.data.data.startDate,
              end_date: res.data.data.endDate,
            };
            const dateArr = []
            if(this.PromotionDetails.start_date !== null){
              dateArr.push(new Date(this.PromotionDetails.start_date))
              dateArr.push(new Date(this.PromotionDetails.end_date))
              this.PromotionDetails.dateArray = dateArr;
            }
          }
        })
        .catch((error) => {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        });
    },

    async updatePromotion(promotionForm) {
      const valid = await this.$refs.simpleRules.validationForm();
      if (!promotionForm.image) {
        this.$refs.simpleRules.loading = true
        setTimeout(() => {
          this.$refs.simpleRules.loading = false
        }, 3000)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Promotion Background Image is required',
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
      if (valid && promotionForm.image) {
        this.$refs.simpleRules.loading = true;
        const formdata = new FormData();
        let start_date = promotionForm.startEndDate !== undefined ? moment(promotionForm?.startEndDate?.[0]).format('YYYY-MM-DD') : '';
        let end_date = promotionForm.startEndDate !== undefined ? moment(promotionForm?.startEndDate?.[1]).format('YYYY-MM-DD') : '';
        formdata.append("image", promotionForm && promotionForm.image ? promotionForm.image : '')
        formdata.append("title", promotionForm && promotionForm.title ? promotionForm.title : '')
        formdata.append("content", promotionForm && promotionForm.content ? promotionForm.content : '')
        formdata.append("is_button", promotionForm && promotionForm.is_button ? promotionForm.is_button : false)
        formdata.append("link", promotionForm && promotionForm.link ? promotionForm.link : '')
        formdata.append("start_date", start_date !== undefined ? start_date : '')
        formdata.append("end_date", end_date  !== undefined ? end_date : '')
        formdata.append("status", promotionForm && promotionForm.status ? promotionForm.status : 0)
        await promotionService.editPromotion(this.$route.params.id, formdata)
          .then((res) => {
            this.$refs.simpleRules.loading = false;
            if (resConditionCheck(res.status) && res.data.data) {
              this.$router.push("/content/promotion-banner/list");
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          })
          .catch((error) => {
            this.$refs.simpleRules.loading = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
  },
};
</script>
